import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dots } from "react-activity";
import { checkSubscriptionStatus } from "../firebase";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "react-activity/dist/library.css";
import "../css/Login.css";

export default function LogInModal(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayIndicator, setDisplayIndicator] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState("password");

  const validAccounts = [
    // Tambahkan email dan password lainnya di sini
    { email: "julianwoliver@gmail.com", password: "admin123" },
  ];

  useEffect(() => {
    let emailStored = localStorage.getItem("email");
    // if (emailStored) handleCheckSubscription(emailStored);
  }, []);

  useEffect(() => {
    setErrorMessage("");
  }, [errorMessage]);

  // const handleCheckSubscription = async (email = email) => {
  //   try {
  //     const result = await checkSubscriptionStatus({ email });
  //     if (result.data.active) {
  //       localStorage.setItem("email", email);
  //       window.location.href = "/courses";
  //       return true;
  //     }
  //   } catch (error) {
  //     console.error("Error checking subscription status:", error.message);
  //     return false;
  //   }
  // };

  const handleLogin = async () => {
    setDisplayIndicator(true);
    // if (email == "sarah@fiddleschool.com") {
    //   localStorage.setItem("email", email);
    //   window.location.href = "/courses";
    //   return;
    // }

    // Cek apakah email cocok tetapi password salah
    const accountWithEmail = validAccounts.find(
      (account) => account.email === email
    );
    if (accountWithEmail && accountWithEmail.password !== password) {
      alert("Your email or password is incorrect");
      setDisplayIndicator(false);
      return;
    }

    // Cek apakah email dan password cocok dengan salah satu akun valid
    const isValidAccount = validAccounts.some(
      (account) => account.email === email && account.password === password
    );

    if (isValidAccount) {
      localStorage.setItem("email", email);
      window.location.href = "/courses";
      return;
    }

    try {
      // step 1: Buat access token
      const accessToken = await createCustomerAccessToken(email, password);
      if (!accessToken) {
        throw new Error("Invalid login credentials.");
      }

      // step 2: Dapatkan detail customer
      const customer = await getCustomerDetails(accessToken);
      if (!customer) {
        throw new Error("Failed to fetch customer details.");
      }

      // // step 3: Cek langganan
      // const hasValidSubscription = await handleCheckSubscription(
      //   customer.email
      // );

      //   if (!hasValidSubscription) {
      //     throw new Error(
      //       "You do not have an active subscription. Please renew or purchase one."
      //     );
      //   }
      localStorage.setItem("email", email.toLowerCase());
      window.location.href = "/courses";
      return true;
    } catch (error) {
      if (error.message === "Unidentified customer") {
        alert("Your email or password is incorrect");
        setDisplayIndicator(false);
        return;
      }
      setErrorMessage(error.message);
      alert(error.message);
      console.error("Login failed:", error.message);
      setErrorMessage && setErrorMessage(error.message);
    } finally {
      setDisplayIndicator(false);
    }
  };

  const createCustomerAccessToken = async (email, password) => {
    const query = `
      mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
        customerAccessTokenCreate(input: $input) {
          customerAccessToken {
            accessToken
            expiresAt
          }
          userErrors {
            field
            message
          }
        }
      }
    `;

    const variables = { input: { email, password } };

    try {
      const response = await fetch(
        "https://c4x3xq-e0.myshopify.com/api/2023-04/graphql.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Shopify-Storefront-Access-Token":
              process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN,
          },
          body: JSON.stringify({ query, variables }),
        }
      );

      const data = await response.json();

      if (data.errors) {
        throw new Error("GraphQL error occurred. Check logs for details.");
      }

      const userErrors = data.data.customerAccessTokenCreate.userErrors;
      if (userErrors.length > 0) {
        throw new Error(userErrors[0].message);
      }

      return data.data.customerAccessTokenCreate.customerAccessToken
        .accessToken;
    } catch (error) {
      throw error;
    }
  };

  const getCustomerDetails = async (accessToken) => {
    const query = `
      query getCustomer($customerAccessToken: String!) {
        customer(customerAccessToken: $customerAccessToken) {
          email
          firstName
          lastName
          orders(first: 10) {
            edges {
              node {
                id
                name
                totalPrice {
                  amount
                  currencyCode
                }
                processedAt
                lineItems(first: 5) {
                  edges {
                    node {
                      title
                      quantity
                      originalTotalPrice {
                        amount
                        currencyCode
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = {
      customerAccessToken: accessToken,
    };

    const response = await fetch(
      "https://c4x3xq-e0.myshopify.com/api/2023-04/graphql.json",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Storefront-Access-Token":
            process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN,
        },
        body: JSON.stringify({ query, variables }),
      }
    );

    const data = await response.json();

    if (data.errors) {
      throw new Error("Failed to fetch customer details.");
    }

    return data.data.customer;
  };

  return (
    <div className="container">
      <div className="header">
        <p className="login-title">Login</p>
      </div>

      <div className="input-group">
        <p className="label">Email</p>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field"
        />
      </div>

      <div className="input-group">
        <p className="label">Password</p>
        <div className="password-container">
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword === "text" ? "text" : "password"}
            className="input-field"
          />
          <div
            onClick={() =>
              setShowPassword(showPassword === "password" ? "text" : "password")
            }
            className="password-toggle"
          >
            {showPassword === "text" ? (
              <FaEye color="black" size={18} />
            ) : (
              <FaEyeSlash color="black" size={18} />
            )}
          </div>
        </div>
      </div>

      <div className="login-button" onClick={handleLogin}>
        <p className="button-text">Log In</p>
        {displayIndicator && (
          <div className="indicator">
            <Dots color="white" />
          </div>
        )}
      </div>

      <p className="signup-text">
        Need an account?&nbsp;&nbsp;
        <Link to="/signup" className="signup-link">
          <span className="signup-underline">Sign Up</span>
        </Link>
      </p>
      <p className="signup-text">
        Forgot your password?&nbsp;&nbsp;
        <Link to="/reset-password" className="signup-link">
          <span className="signup-underline">Reset Password</span>
        </Link>
      </p>
    </div>
  );
}
